import { useRoutes } from 'react-router-dom';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { createTheme, IconButton, ThemeProvider } from '@mui/material';
import MetbusSplashScreen from 'components/MetbusSplashScreen';
import { esES } from '@mui/material/locale';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
// import useSettings from './hooks/useSettings';
import routes from './routes';
// import { createTheme } from './theme';
import { SnackbarUtilsConfigurator } from 'src/utils/snackbarUtilsConfigurator';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a client
const queryClient = new QueryClient();
const App = () => {
  const content = useRoutes(routes);
  // const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  // const theme = createTheme({
  //   responsiveFontSizes: settings.responsiveFontSizes,
  //   roundedCorners: settings.roundedCorners,
  //   theme: settings.theme,
  //   //typography: settings.typography,
  // });

  const theme = createTheme({}, esES);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          dense
          maxSnack={3}
          autoHideDuration={3000}
          preventDuplicate
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          action={(snackbarId) => (
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => closeSnackbar(snackbarId)}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          <SnackbarUtilsConfigurator />
          {/* <GlobalStyles2 /> */}
          {auth.isInitialized ? content : <MetbusSplashScreen />}
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
